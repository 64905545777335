<template>
  <v-card :loading="loading" elevation="0">
    <v-card-text>
      <v-container>
        <v-form ref="form" v-model="valide" lazy-validation>
          <div class="card-body">
            <v-row>
              <v-col cols="6">
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="loading"
                                  :label="$t('settings.lang_mailSettingsOutServer')"
                                  :rules="[rules.required, rules.emailServer]"
                                  @focus="showTouchKeyboard"
                                  autocomplete="off"
                                  dense
                                  outlined
                                  required
                                  v-model="form.voucherPortal_mailServer"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="loading"
                                  :label="$t('settings.lang_mailSettingsUsername')"
                                  :rules="[rules.email,rules.required]"
                                  @focus="showTouchKeyboard"
                                  autocomplete="off"
                                  dense
                                  outlined
                                  required
                                  v-model="form.voucherPortal_mailserverUser"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select :disabled="loading" :items="encryptions"
                              :label="$t('settings.lang_mailSettingsEncryption')"
                              dense
                              item-text="name"
                              item-value="value"
                              outlined
                              v-model="form.voucherPortal_mailserverEncryption"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="form.voucherPortal_reply_to_address" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  dense :label="$t('generic.lang_replyToAddress')" outlined @focus="showTouchKeyboard"/>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="loading"
                                  :label="$t('settings.lang_mailSetsPort')"
                                  :rules="[rules.required]"
                                  @focus="showTouchKeyboard"
                                  autocomplete="off"
                                  dense
                                  max="64000"
                                  min="0"
                                  outlined
                                  required
                                  type="number"
                                  v-model="form.voucherPortal_mailserverPort"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="loading"
                                  :label="$t('settings.lang_mailSettingsPassword')"
                                  :rules="[rules.required,rules.min]"
                                  @focus="showTouchKeyboard"
                                  dense
                                  outlined
                                  required
                                  type="password"
                                  v-model="form.voucherPortal_mailserverPassword"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="loading"
                                  :label="$t('settings.lang_mailSetsSenderName')"
                                  :rules="[rules.required]"
                                  @focus="showTouchKeyboard"
                                  autocomplete="off"
                                  dense
                                  outlined
                                  required
                                  v-model="form.voucherPortal_from_Name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="loading"
                                  :label="$t('settings.lang_mailSetsEmail')"
                                  :rules="[rules.email,rules.required]"
                                  @focus="showTouchKeyboard"

                                  autocomplete="off"
                                  dense
                                  outlined
                                  required
                                  v-model="form.voucherPortal_from_address"
                    ></v-text-field>
                  </v-col>

                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions align="end" class="border-top">
      <v-row justify="end" no-gutters>
        <v-btn @click="mailServerTest()" color="info" large >
          {{ $t('settings.lang_mailTest') }}
        </v-btn>
        <v-btn :disabled="loading||!valide" @click="update()" color="success" large>
          {{ $t('generic.lang_apply') }}
        </v-btn>
      </v-row>
    </v-card-actions>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                          :accept="hideTouchKeyboard"
                          :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidth"/>
    </div>
  </v-card>
</template>

<script>
import mixin from "@/mixins/KeyboardMixIns";
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";

export default {
  name: "OnlineGiftCardSaleEmailConfigComponent",
  mixins: [mixin],
  data() {
    return {
      loading: false,
      valide: false,
      rules: {
        email: (email) => {
          let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(email) || this.$t('generic.lang_requiredField');
        },
        required: (field) => {
          return ((field && field !== '' && field.toString().length > 0) || this.$t('generic.lang_requiredField'))
        },
        min: (field) => {
          return ((field && field.toString().length >= 6) || this.$t('generic.lang_passwordShouldBeAtLeast6CharsMinimum'))
        },
        emailServer: (value) => {
          const pattern = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\\-]*[A-Za-z0-9])$/gm;
          
          return pattern.test(value) || this.$t('generic.lang_invalidEmailServer');
        },
      },
      encryptions: [
        {name: this.$t('generic.lang_gen_none'), value: 0},
        {name: 'SSL', value: 1},
        {name: 'TLS', value: 2},
      ],
      form: {
        voucherPortal_reply_to_address: "",
        voucherPortal_from_Name: "",
        voucherPortal_from_address: "",
        voucherPortal_mailserverEncryption: 0,
        voucherPortal_mailServer: '',
        voucherPortal_mailserverPort: '',
        voucherPortal_mailserverUser: '',
        voucherPortal_mailserverPassword: '',
      }
    }
  },
  mounted() {
    this.getEmailsConfig();
  },
  methods: {
    getEmailsConfig() {
      this.loading = true;

      this.axios.post(ENDPOINTS.VOUCHERBEE.SETTINGS.EMAIL.CONFIG.GET)
          .then(res => {
            this.form = res.data.formfillData.textFields;
          }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    },
    update() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      this.axios.post(ENDPOINTS.VOUCHERBEE.SETTINGS.EMAIL.CONFIG.UPDATE, this.form).then(res => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_updatedSuccessfully'),
          color: "success",
        });
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    },
    mailServerTest() {

      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      this.axios.post(ENDPOINTS.VOUCHERBEE.SETTINGS.EMAIL.TEST, {}).then(res => {
        if (res.data.status === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_ActionSuccessful'),
            color: "success",
          });
        } else {
          Events.$emit("showSnackbar", {
            message: res.data.error,
            color: "error"
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })

    }
  }
}
</script>

<style scoped>

</style>

