<template>
    <v-row>
        <v-col cols="12">
            <v-card :disabled="loading" :loading="loading">
              <v-card-title>
                {{$t('settings.lang_giftcard_images')}}
              </v-card-title>
              <v-divider class="ma-0"/>
                <v-card-text>
                    <v-container>
                        <v-row>

                            <v-col cols="9">
                                <v-btn color="primary" @click="showImageUploadDialog = true">{{$t('generic.lang_uploadImage')}}</v-btn>

                                <v-container fluid>
                                    <v-row>
                                        <v-col
                                                v-for="image in templates"
                                                :key="image.uuid"
                                                class="d-flex child-flex"
                                                cols="4"
                                        >
                                            <TemplateImageComponent @imageDelete="imageDeleted" :image="image.image" :uuid="image.uuid"></TemplateImageComponent>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" :loading="loading" :disabled="loading" @click="saveTemplate">{{ $t('generic.lang_save') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
        <TemplateImageUploadDialog :show-dialog="showImageUploadDialog" :template="templates" @imageUploaded="imageUploaded" @closeDialog="showImageUploadDialog = false"></TemplateImageUploadDialog>
    </v-row>
</template>

<script>
    import {ENDPOINTS} from "@/config";
    import TemplateImageComponent from "@/components/settings/moduleSettings/onlinegiftcardsale/imageTemplates/TemplateImageComponent";
    import TemplateImageUploadDialog from "@/components/settings/moduleSettings/onlinegiftcardsale/imageTemplates/TemplateImageUploadDialog";
    import TemplateLogoUploadDialog from "@/components/settings/moduleSettings/onlinegiftcardsale/imageTemplates/TemplateLogoUploadDialog";
    import {mapState} from "vuex";

    export default {
        name: "ImagesTemplate",

        components: {
            TemplateLogoUploadDialog,
            TemplateImageUploadDialog,
          TemplateImageComponent
        },

        data() {
            return {
                templates:[],
                loading: false,
                deleteLogoLoading: false,
                showLogoUploadDialog: false,
                showImageUploadDialog: false
            }
        },

        mounted() {
            this.getTemplate();
        },

        computed: {
            ...mapState([
                'api'
            ]),
        },

        methods: {
            getTemplate() {
                this.loading = true;

                this.axios.post(ENDPOINTS.VOUCHERBEE.SETTINGS.IMAGETEMPLATE.GETALL, {

                }).then((res) => {
                    if(res.data.success) {
                        this.templates = res.data.data;
                    }
                }).finally(()=>{
                  this.loading = false;
                })
            },
            saveTemplate() {
                this.loading = true;

                this.axios.post(ENDPOINTS.KIOSK.IMAGETEMPLATES.UPDATE, {
                    templateID: this.template.uuid,
                    alias: this.template.alias
                }).then((res) => {
                    if(res.data.success) {
                      this.$router.push('/kiosk/images-template');
                    }
                }).finally(()=>{
                  this.loading = false;
                })
            },
            logoUploaded() {

                this.showLogoUploadDialog = false;
                this.getTemplate();
            },
            imageUploaded() {

                this.showImageUploadDialog = false;
                this.getTemplate();
            },
            imageDeleted() {

                this.getTemplate();
            }
        }
    }
</script>