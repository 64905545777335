<template>
  <b-card
      no-body
      class="mb-1 transparent"
  >
    <v-toolbar
        elevation="0"
        max-height="40"
        class="mb-6 transparent"
    >
      <v-tabs
          v-model="tab"
          height="50"
      >
        <v-tab>
          {{ $t('settings.lang_receiptSetFooter') }}
        </v-tab>
        <v-tab>
          {{ $t('generic.lang_homePage') }}
        </v-tab>
        <v-spacer></v-spacer>
        <v-btn
            depressed
            color="success"
            @click="update"
            :loading="loadingLayout"
        >
          {{ $t('generic.lang_save') }}
        </v-btn>
      </v-tabs>
    </v-toolbar>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <b-tabs>
          <b-tab active>
            <template #title>
              <v-btn
                  text
                  class="ma-0"
              >
                <country-flag country="DE"/>
              </v-btn>
            </template>
            <b-card
                :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']"
            >
              <v-row no-gutters>
                <v-col
                    class="pb-6"
                    cols="12"
                >
                  <v-subheader
                  ><strong>Datenschutz-Bestimmungen</strong></v-subheader
                  >
                  <quill-editor
                      id="privacy_editor_9"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      :options="quillOptions"
                      @focus="showTouchKeyboard"
                      output="html"
                      class="mb-4"
                      v-model="dynamicContent.german.privacyPolicy"
                  >
                  </quill-editor>
                </v-col>
              </v-row>
            </b-card>
          </b-tab>

          <b-tab>
            <template #title>
              <v-btn
                  text
                  class="ma-0"
              >
                <country-flag country="GB"/>
              </v-btn>
            </template>
            <b-card
                :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']"
            >
              <v-row no-gutters>
                <v-col
                    class="pb-6"
                    cols="12"
                >
                  <v-subheader><strong>Privacy Policy</strong></v-subheader>
                  <quill-editor
                      id="privacy_editor_2"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      :options="quillOptions"
                      @focus="showTouchKeyboard"
                      output="html"
                      class="mb-4"
                      v-model="dynamicContent.english.privacyPolicy"
                  >
                  </quill-editor>
                </v-col>
              </v-row>
            </b-card>
          </b-tab>

          <b-tab>
            <template #title>
              <v-btn
                  text
                  class="ma-0"
              >
                <country-flag country="FR"/>
              </v-btn>
            </template>
            <b-card
                :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']"
            >
              <v-row no-gutters>
                <v-col
                    class="pb-6"
                    cols="12"
                >
                  <v-subheader
                  ><strong>Politique de confidentialité</strong>
                  </v-subheader>
                  <quill-editor
                      id="privacy_editor_3"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      :options="quillOptions"
                      @focus="showTouchKeyboard"
                      output="html"
                      class="mb-4"
                      v-model="dynamicContent.french.privacyPolicy"
                  >
                  </quill-editor>
                </v-col>
              </v-row>
            </b-card>
          </b-tab>

          <b-tab>
            <template #title>
              <v-btn
                  text
                  class="ma-0"
              >
                <country-flag country="SA"/>
              </v-btn>
            </template>
            <b-card
                :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']"
            >
              <v-row no-gutters>
                <v-col
                    class="pb-6"
                    cols="12"
                >
                  <v-subheader><strong>سياسةالخصوصية</strong></v-subheader>
                  <quill-editor
                      id="privacy_editor_1"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      :options="quillOptions"
                      @focus="showTouchKeyboard"
                      output="html"
                      class="mb-4"
                      v-model="dynamicContent.arabic.privacyPolicy"
                  >
                  </quill-editor>
                </v-col>
              </v-row>
            </b-card>
          </b-tab>
        </b-tabs>
        <v-divider class="ma-0"/>
        <br/><br/>
        <b-tabs>
          <b-tab active>
            <template #title>
              <v-btn
                  text
                  class="ma-0"
              >
                <country-flag country="DE"/>
              </v-btn>
            </template>
            <b-card
                :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']"
            >
              <v-row no-gutters>
                <v-col
                    class="pb-6"
                    cols="12"
                >
                  <v-subheader
                  ><strong>Geschäftsbedingungen</strong></v-subheader
                  >
                  <quill-editor
                      id="privacy_editor_7"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      :options="quillOptions"
                      @focus="showTouchKeyboard"
                      output="html"
                      class="mb-4"
                      v-model="dynamicContent.german.termsAndConditions"
                  >
                  </quill-editor>
                </v-col>
              </v-row>
            </b-card>
          </b-tab>

          <b-tab>
            <template #title>
              <v-btn
                  text
                  class="ma-0"
              >
                <country-flag country="GB"/>
              </v-btn>
            </template>
            <b-card
                :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']"
            >
              <v-row no-gutters>
                <v-col
                    class="pb-6"
                    cols="12"
                >
                  <v-subheader
                  ><strong>Terms and Conditions</strong></v-subheader
                  >
                  <quill-editor
                      id="privacy_editor_2"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      :options="quillOptions"
                      @focus="showTouchKeyboard"
                      output="html"
                      class="mb-4"
                      v-model="dynamicContent.english.termsAndConditions"
                  >
                  </quill-editor>
                </v-col>
              </v-row>
            </b-card>
          </b-tab>

          <b-tab>
            <template #title>
              <v-btn
                  text
                  class="ma-0"
              >
                <country-flag country="FR"/>
              </v-btn>
            </template>
            <b-card
                :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']"
            >
              <v-row no-gutters>
                <v-col
                    class="pb-6"
                    cols="12"
                >
                  <v-subheader
                  ><strong>Termes et conditions</strong>
                  </v-subheader>
                  <quill-editor
                      id="privacy_editor_3"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      :options="quillOptions"
                      @focus="showTouchKeyboard"
                      output="html"
                      class="mb-4"
                      v-model="dynamicContent.french.termsAndConditions"
                  >
                  </quill-editor>
                </v-col>
              </v-row>
            </b-card>
          </b-tab>

          <b-tab>
            <template #title>
              <v-btn
                  text
                  class="ma-0"
              >
                <country-flag country="SA"/>
              </v-btn>
            </template>
            <b-card
                :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']"
            >
              <v-row no-gutters>
                <v-col
                    class="pb-6"
                    cols="12"
                >
                  <v-subheader><strong>الأحكام والشروط</strong></v-subheader>
                  <quill-editor
                      id="privacy_editor_2"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      :options="quillOptions"
                      @focus="showTouchKeyboard"
                      output="html"
                      class="mb-4"
                      v-model="dynamicContent.arabic.termsAndConditions"
                  >
                  </quill-editor>
                </v-col>
              </v-row>
            </b-card>
          </b-tab>
        </b-tabs>
        <v-divider class="ma-0"/>
        <br/><br/>
        <b-tabs>
          <b-tab active>
            <template #title>
              <v-btn
                  text
                  class="ma-0"
              >
                <country-flag country="DE"/>
              </v-btn>
            </template>
            <b-card
                :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']"
            >
              <v-row no-gutters>
                <v-col
                    class="pb-6"
                    cols="12"
                >
                  <v-subheader><strong>Impressum</strong></v-subheader>
                  <quill-editor
                      id="privacy_editor_5"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      :options="quillOptions"
                      @focus="showTouchKeyboard"
                      output="html"
                      class="mb-4"
                      v-model="dynamicContent.german.imprints"
                  >
                  </quill-editor>
                </v-col>
              </v-row>
            </b-card>
          </b-tab>

          <b-tab>
            <template #title>
              <v-btn
                  text
                  class="ma-0"
              >
                <country-flag country="GB"/>
              </v-btn>
            </template>
            <b-card
                :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']"
            >
              <v-row no-gutters>
                <v-col
                    class="pb-6"
                    cols="12"
                >
                  <v-subheader><strong>Imprints</strong></v-subheader>
                  <quill-editor
                      id="privacy_editor_2"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      :options="quillOptions"
                      @focus="showTouchKeyboard"
                      output="html"
                      class="mb-4"
                      v-model="dynamicContent.english.imprints"
                  >
                  </quill-editor>
                </v-col>
              </v-row>
            </b-card>
          </b-tab>

          <b-tab>
            <template #title>
              <v-btn
                  text
                  class="ma-0"
              >
                <country-flag country="FR"/>
              </v-btn>
            </template>
            <b-card
                :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']"
            >
              <v-row no-gutters>
                <v-col
                    class="pb-6"
                    cols="12"
                >
                  <v-subheader><strong>Empreintes</strong></v-subheader>
                  <quill-editor
                      id="privacy_editor_3"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      :options="quillOptions"
                      @focus="showTouchKeyboard"
                      output="html"
                      class="mb-4"
                      v-model="dynamicContent.french.imprints"
                  >
                  </quill-editor>
                </v-col>
              </v-row>
            </b-card>
          </b-tab>

          <b-tab>
            <template #title>
              <v-btn
                  text
                  class="ma-0"
              >
                <country-flag country="SA"/>
              </v-btn>
            </template>
            <b-card
                :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']"
            >
              <v-row no-gutters>
                <v-col
                    class="pb-6"
                    cols="12"
                >
                  <v-subheader><strong>ختم الناشر</strong></v-subheader>
                  <quill-editor
                      id="privacy_editor_3"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      :options="quillOptions"
                      @focus="showTouchKeyboard"
                      output="html"
                      class="mb-4"
                      v-model="dynamicContent.arabic.imprints"
                  >
                  </quill-editor>
                </v-col>
              </v-row>
            </b-card>
          </b-tab>
        </b-tabs>
      </v-tab-item>
      <v-tab-item>
        <b-tabs>
          <b-tab active>
            <template #title>
              <v-btn
                  text
                  class="ma-0"
              >
                <country-flag country="DE"/>
              </v-btn>
            </template>
            <b-card
                :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']"
            >
              <v-row no-gutters>
                <v-col
                    class="pb-6"
                    cols="12"
                >
                  <v-subheader
                  ><strong>{{
                      $t('generic.lang_title')
                    }}</strong></v-subheader
                  >
                  <quill-editor
                      id="privacy_editor_8"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      :options="quillOptions"
                      @focus="showTouchKeyboard"
                      output="html"
                      class="mb-4"
                      v-model="homePage.german.title"
                  >
                  </quill-editor>
                </v-col>
                <v-col
                    class=""
                    cols="12"
                >
                  <v-subheader
                  ><strong>{{
                      $t('generic.lang_description')
                    }}</strong></v-subheader
                  >
                  <quill-editor
                      id="privacy_editor"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      :options="quillOptions"
                      @focus="showTouchKeyboard"
                      output="html"
                      class="mb-4"
                      v-model="homePage.german.description"
                  >
                  </quill-editor>
                </v-col>
                <v-col
                    class=""
                    cols="12"
                >
                  <v-subheader
                  ><strong>{{
                      $t('generic.lang_footer')
                    }}</strong></v-subheader
                  >
                  <quill-editor
                      id="privacy_editor"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      :options="quillOptions"
                      @focus="showTouchKeyboard"
                      output="html"
                      class="mb-4"
                      v-model="homePage.german.footer"
                  >
                  </quill-editor>
                </v-col>
              </v-row>
            </b-card>
          </b-tab>

          <b-tab>
            <template #title>
              <v-btn
                  text
                  class="ma-0"
              >
                <country-flag country="GB"/>
              </v-btn>
            </template>
            <b-card
                :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']"
            >
              <v-row no-gutters>
                <v-col
                    class="pb-6"
                    cols="12"
                >
                  <v-subheader
                  ><strong>{{
                      $t('generic.lang_title')
                    }}</strong></v-subheader
                  >
                  <quill-editor
                      id="privacy_editor_2"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      :options="quillOptions"
                      @focus="showTouchKeyboard"
                      output="html"
                      class="mb-4"
                      v-model="homePage.english.title"
                  >
                  </quill-editor>
                </v-col>
                <v-col
                    class="pb-6"
                    cols="12"
                >
                  <v-subheader
                  ><strong>{{
                      $t('generic.lang_description')
                    }}</strong></v-subheader
                  >
                  <quill-editor
                      id="privacy_editor_2"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      :options="quillOptions"
                      @focus="showTouchKeyboard"
                      output="html"
                      class="mb-4"
                      v-model="homePage.english.description"
                  >
                  </quill-editor>
                </v-col>
                <v-col
                    class=""
                    cols="12"
                >
                  <v-subheader
                  ><strong>{{
                      $t('generic.lang_footer')
                    }}</strong></v-subheader
                  >
                  <quill-editor
                      id="privacy_editor"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      :options="quillOptions"
                      @focus="showTouchKeyboard"
                      output="html"
                      class="mb-4"
                      v-model="homePage.english.footer"
                  >
                  </quill-editor>
                </v-col>
              </v-row>
            </b-card>
          </b-tab>

          <b-tab>
            <template #title>
              <v-btn
                  text
                  class="ma-0"
              >
                <country-flag country="FR"/>
              </v-btn>
            </template>
            <b-card
                :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']"
            >
              <v-row no-gutters>
                <v-col
                    class="pb-6"
                    cols="12"
                >
                  <v-subheader
                  ><strong>{{ $t('generic.lang_title') }}</strong>
                  </v-subheader>
                  <quill-editor
                      id="privacy_editor_3"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      :options="quillOptions"
                      @focus="showTouchKeyboard"
                      output="html"
                      class="mb-4"
                      v-model="homePage.french.title"
                  >
                  </quill-editor>
                </v-col>
                <v-col
                    class="pb-6"
                    cols="12"
                >
                  <v-subheader
                  ><strong>{{ $t('generic.lang_description') }}</strong>
                  </v-subheader>
                  <quill-editor
                      id="privacy_editor_3"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      :options="quillOptions"
                      @focus="showTouchKeyboard"
                      output="html"
                      class="mb-4"
                      v-model="homePage.french.description"
                  >
                  </quill-editor>
                </v-col>
                <v-col
                    class=""
                    cols="12"
                >
                  <v-subheader
                  ><strong>{{
                      $t('generic.lang_footer')
                    }}</strong></v-subheader
                  >
                  <quill-editor
                      id="privacy_editor"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      :options="quillOptions"
                      @focus="showTouchKeyboard"
                      output="html"
                      class="mb-4"
                      v-model="homePage.french.footer"
                  >
                  </quill-editor>
                </v-col>
              </v-row>
            </b-card>
          </b-tab>

          <b-tab>
            <template #title>
              <v-btn
                  text
                  class="ma-0"
              >
                <country-flag country="SA"/>
              </v-btn>
            </template>
            <b-card
                :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']"
            >
              <v-row no-gutters>
                <v-col
                    class="pb-6"
                    cols="12"
                >
                  <v-subheader
                  ><strong>{{
                      $t('generic.lang_title')
                    }}</strong></v-subheader
                  >
                  <quill-editor
                      id="privacy_editor_3"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      :options="quillOptions"
                      @focus="showTouchKeyboard"
                      output="html"
                      class="mb-4"
                      v-model="homePage.arabic.title"
                  >
                  </quill-editor>
                </v-col>
                <v-col
                    class="pb-6"
                    cols="12"
                >
                  <v-subheader
                  ><strong>{{
                      $t('generic.lang_description')
                    }}</strong></v-subheader
                  >
                  <quill-editor
                      id="privacy_editor_4"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      :options="quillOptions"
                      @focus="showTouchKeyboard"
                      output="html"
                      class="mb-4"
                      v-model="homePage.arabic.description"
                  >
                  </quill-editor>
                </v-col>
                <v-col
                    class=""
                    cols="12"
                >
                  <v-subheader
                  ><strong>{{
                      $t('generic.lang_footer')
                    }}</strong></v-subheader
                  >
                  <quill-editor
                      id="privacy_editor"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      :options="quillOptions"
                      @focus="showTouchKeyboard"
                      output="html"
                      class="mb-4"
                      v-model="homePage.arabic.footer"
                  >
                  </quill-editor>
                </v-col>
              </v-row>
            </b-card>
          </b-tab>
        </b-tabs>
      </v-tab-item>
    </v-tabs-items>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard
          :accept="hideTouchKeyboard"
          :cancel="hideTouchKeyboard"
          :defaultKeySet="touchKeyboard.keySet"
          :input="touchKeyboard.input"
          :layout="touchKeyboard.layout"
          :options="touchKeyboard.options"
          class="internalWidth"
          id="onScreenKeyboard"
          v-if="touchKeyboard.visible"
      />
    </div>
  </b-card>
</template>

<script>
import mixin from '@/mixins/KeyboardMixIns';
import CountryFlag from 'vue-country-flag';
import {ENDPOINTS} from '@/config';
import {Events} from '@/plugins/events';
import {createNamespacedHelpers} from 'vuex';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import {quillEditor} from 'vue-quill-editor';

import Quill from 'quill';
import QuillResize from 'quill-resize-module';

Quill.register('modules/resize', QuillResize);

export default {
  name: 'OnlineGiftCardSaleDynamicContentComponent',
  mixins: [mixin],
  components: {
    CountryFlag,
    quillEditor,
  },
  data() {
    return {
      loadingLayout: false,
      tab: 0,
      homePage: {
        arabic: {
          title: '',
          description: '',
          footer: '',
        },
        french: {
          title: '',
          description: '',
          footer: '',
        },
        english: {
          title: '',
          description: '',
          footer: '',
        },
        german: {
          title: '',
          description: '',
          footer: '',
        },
      },
      dynamicContent: {
        arabic: {
          agb: '',
          imprints: '',
          privacyPolicy: '',
          termsAndConditions: '',
        },
        english: {
          agb: '',
          imprints: '',
          privacyPolicy: '',
          termsAndConditions: '',
        },
        french: {
          agb: '',
          imprints: '',
          privacyPolicy: '',
          termsAndConditions: '',
        },
        german: {
          agb: '',
          imprints: '',
          privacyPolicy: '',
          termsAndConditions: '',
        },
      },

      quillOptions: {
        // register the image resize module

        modules: {
          resize: {
            modules: ['Resize', 'DisplaySize', 'Toolbar'],
          },
          toolbar: [
            // show image picker
            ['image', 'video', 'link', 'clean'],
            ['bold', 'italic', 'underline', 'strike'],
            [{header: [1, 2, 3, 4, 5, 6, false]}],
            [{align: []}],
            [{font: []}],
            [
              {list: 'ordered'},
              {list: 'bullet'},
              {indent: '-1'},
              {indent: '+1'},
            ],
          ],
        },
      },
    };
  },
  methods: {
    updateHomePageContent() {
      this.axios
          .post(ENDPOINTS.VOUCHERBEE.SETTINGS.DYNAMICCONTENT.UPDATEHOME, {
            title_de: this.homePage.german.title,
            footer_de: this.homePage.german.footer,
            description_de: this.homePage.german.description,
            title_en: this.homePage.english.title,
            footer_en: this.homePage.english.footer,
            description_en: this.homePage.english.description,
            title_fr: this.homePage.french.title,
            footer_fr: this.homePage.french.footer,
            description_fr: this.homePage.french.description,
            title_ar: this.homePage.arabic.title,
            footer_ar: this.homePage.arabic.footer,
            description_ar: this.homePage.arabic.description,
          })
          .then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit('showSnackbar', {
                message: this.$t('generic.lang_actionSuccessful'),
                color: 'success',
              });
            } else {
              Events.$emit('showSnackbar', {
                message: this.$t('generic.lang_errorOccurred'),
                color: 'error',
              });
            }
          })
          .catch((err) => {
            Events.$emit('showSnackbar', {
              message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
              color: 'error',
            });
          });
    },
    updateFooterContent() {
      this.axios
          .post(ENDPOINTS.VOUCHERBEE.SETTINGS.DYNAMICCONTENT.UPDATEFOOTER, {
            privacyPolicy_ar: this.dynamicContent.arabic.privacyPolicy,
            agb_ar: this.dynamicContent.arabic.agb,
            termsAndConditions_ar: this.dynamicContent.arabic.termsAndConditions,
            imprints_ar: this.dynamicContent.arabic.imprints,
            privacyPolicy_fr: this.dynamicContent.french.privacyPolicy,
            agb_fr: this.dynamicContent.french.agb,
            termsAndConditions_fr: this.dynamicContent.french.termsAndConditions,
            imprints_fr: this.dynamicContent.french.imprints,
            privacyPolicy_en: this.dynamicContent.english.privacyPolicy,
            agb_en: this.dynamicContent.english.agb,
            termsAndConditions_en: this.dynamicContent.english.termsAndConditions,
            imprints_en: this.dynamicContent.english.imprints,
            privacyPolicy_de: this.dynamicContent.german.privacyPolicy,
            agb_de: this.dynamicContent.german.agb,
            termsAndConditions_de: this.dynamicContent.german.termsAndConditions,
            imprints_de: this.dynamicContent.german.imprints,
          })
          .then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit('showSnackbar', {
                message: this.$t('generic.lang_actionSuccessful'),
                color: 'success',
              });
            } else {
              Events.$emit('showSnackbar', {
                message: this.$t('generic.lang_errorOccurred'),
                color: 'error',
              });
            }
          })
          .catch((err) => {
            Events.$emit('showSnackbar', {
              message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
              color: 'error',
            });
          });
    },
    update() {
      if (this.tab === 0) {
        this.updateFooterContent();
      } else if (this.tab === 1) {
        this.updateHomePageContent();
      }
    },
    getFooterContent() {
      this.axios
          .post(ENDPOINTS.VOUCHERBEE.SETTINGS.DYNAMICCONTENT.GETFOOTER)
          .then((res) => {
            if (res.status === 200) {
              Object.assign(this.dynamicContent, res.data);
            } else {
              Events.$emit('showSnackbar', {
                message: this.$t('generic.lang_errorOccurred'),
                color: 'error',
              });
            }
          })
          .catch((err) => {
            Events.$emit('showSnackbar', {
              message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
              color: 'error',
            });
          });
    },
    getHomePageContent() {
      this.axios
          .post(ENDPOINTS.VOUCHERBEE.SETTINGS.DYNAMICCONTENT.GETHOME)
          .then((res) => {
            if (res.status === 200) {
              Object.assign(this.homePage, res.data);
            } else {
              Events.$emit('showSnackbar', {
                message: this.$t('generic.lang_errorOccurred'),
                color: 'error',
              });
            }
          })
          .catch((err) => {
            Events.$emit('showSnackbar', {
              message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
              color: 'error',
            });
          });
    },
  },
  mounted() {
    this.getFooterContent();
    this.getHomePageContent();
  },
};
</script>

<style scoped>
#privacy_editor_4,
#privacy_editor_1,
#privacy_editor_2,
#privacy_editor_3,
#privacy_editor_5,
#privacy_editor_7,
#privacy_editor_8,
#privacy_editor_9 {
  height: 300px !important;
}
</style>
