<template>
  <v-dialog persistent scrollable :value="showDialog" max-width="700">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>{{ $t('generic.lang_uploadImage') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog" :disabled="loading">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider class="ma-0"/>
      <v-card-text>
        <v-container fluid>
            <v-form v-model="valid" ref="imageTemplateForm">
          <v-row justify="center">
              <v-col cols="12" v-if="previewImage">
              <v-img contain  height="400" :src="previewImage" />
            </v-col>
            <v-col cols="12">
              <v-text-field dense hide-details :label="$t('generic.lang_alias')" v-model="alias" outlined
              :rules="[rules.required]"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field type="number" dense hide-details :label="$t('generic.lang_position')" v-model="position" outlined
            ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-alert type="info" outlined dense>
                {{$t('generic.lang_voucherbeePreferableDims')}}
              </v-alert>
              <v-file-input
                  dense
                  outlined
                  show-size
                  :label="$t('generic.lang_selectImage')"
                  accept="image/*"
                  :rules="[rules.required]"
                  @change="selectImage"
              ></v-file-input>
            </v-col>
          </v-row>
        </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-btn text color="error" @click="closeDialog" :disabled="loading">{{ $t('generic.lang_cancel') }}</v-btn>
        <v-spacer/>
        <v-btn color="success" @click="uploadImage" :loading="loading" :disabled="loading || !valid || !image">
          {{ $t('generic.lang_hochladen') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {ENDPOINTS} from "@/config";
import validation from "../../../../../mixins/validation/validation";

export default {
  name: "TemplateImageUploadDialog",

  components: {
  },

  props: {
    showDialog: Boolean
  },

  mixins:[validation],

  data() {
    return {
      valid: true,
      image: null,
      alias: null,
      position: null,
      previewImage: null,
      loading: false
    }
  },

  watch: {
    showDialog(val) {
      if (!val) {
        this.image = null;
        this.$refs?.imageTemplateForm.reset();
      }
    }
  },

  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    uploadImage() {
      if(!this.$refs?.imageTemplateForm.validate()){
        return;
      }
      this.loading = true;
      this.axios.post(ENDPOINTS.VOUCHERBEE.SETTINGS.IMAGETEMPLATE.CREATE, {
        alias: this.alias,
        uuid: this.uuid,
        position: this.position,
        uploadImage: this.image
      }).then((res) => {
        if (res.data.success) {
          this.image=null;
          this.previewImage=null;
          this.$refs?.imageTemplateForm.reset();
          this.$emit("imageUploaded");
        }
      }).catch(() => {

      }).finally(() => {
        this.loading = false;
      })
    },
    async selectImage(image) {
      this.image = await this.getBase64(image);
      this.previewImage = URL.createObjectURL(image);
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        if(!file)
          return;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
  }
}
</script>