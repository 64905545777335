<template>
  <v-card :loading="loading">
    <b-tabs content-class="mt-3">
      <b-tab :title="'General Information'" class="active">
        <v-container>
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="loading"
                              :label="$t('generic.lang_displayedName')"
                              :rules="[rules.required]"
                              @focus="showTouchKeyboard"
                              autocomplete="off"
                              dense
                              outlined
                              required
                              v-model="displayedName"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="loading"
                              :label="$t('localbee.lang_lbRestaurantStreet')"
                              :rules="[rules.required]"
                              @focus="showTouchKeyboard"
                              autocomplete="off"
                              dense
                              outlined
                              required
                              v-model="street"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="loading"
                              :label="$t('localbee.lang_lbRestaurantStreetNo')"
                              :rules="[rules.required]"
                              @focus="showTouchKeyboard"
                              autocomplete="off"
                              dense
                              outlined
                              required
                              v-model="houseNumber"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="loading"
                              :label="$t('customers.lang_cust_zip')"
                              :rules="[rules.required]"
                              @focus="showTouchKeyboard"
                              autocomplete="off"
                              dense
                              outlined
                              required
                              v-model="zipCode"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="loading"
                              :label="$t('settings.lang_city')"
                              :rules="[rules.required]"
                              @focus="showTouchKeyboard"
                              autocomplete="off"
                              dense
                              outlined
                              required
                              v-model="city"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="loading"
                              :label="$t('generic.lang_emailAdresse')"
                              :rules="[rules.required]"
                              @focus="showTouchKeyboard"
                              autocomplete="off"
                              dense
                              outlined
                              required
                              v-model="email"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="loading"
                              :label="$t('generic.lang_phoneNumber')"
                              :rules="[rules.required]"
                              @focus="showTouchKeyboard"
                              autocomplete="off"
                              dense
                              outlined
                              type="number"
                              v-model="phone"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-select
                  v-model="eventbee_webshop_cashierId"
                  :items="cashiers"
                  item-text="name"
                  item-value="id"
                  :label="$t('eventbee.lang_webshop_cashierId')"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
          </v-form>
          <v-card-actions class="pa-1">
            <v-row>
              <v-col cols="12" align="end">
                <v-btn large color="success" :loading="loading" :disabled="!valid" @click="update">
                  {{ $t('generic.lang_save') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-container>
      </b-tab>
    </b-tabs>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                          :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidth"
                          id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
    </div>
  </v-card>
</template>

<script>
import mixin from "@/mixins/KeyboardMixIns";
import {Events} from "@/plugins/events";
import {ENDPOINTS} from "@/config";

export default {
  name: "OnlineGiftCardSaleGeneralInfoComponent",
  components: {},
  mixins: [mixin],
  data() {
    return {
      ENDPOINTS,
      rules: {
        required: (field) => {
          return ((field && field !== '' && field.toString().length > 0) || this.$t('generic.lang_requiredField'))
        },
      },
      selfService_subdomain: '',
      cashiers: [],
      loading: false,
      loading2: false,
      valid: false,
      valid2: false,

      displayedName: null,
      street: null,
      houseNumber: null,
      zipCode: null,
      city: null,
      email: null,
      phone: null,
      shortDesc: null,
      fullDesc: null,
      paymentInfo: null,
      imprintText: null,
      emailFooterText: null,
      globalProductInfo: null,
      eventbee_webshop_cashierId: null,
    }
  },
  computed: {},
  methods: {
    getData() {
      this.loading = true;

      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.GENERALINFO.GET)
          .then(res => {
            this.displayedName = res.data.formfillData.textFields.lb_restaurantName;
            this.street = res.data.formfillData.textFields.lb_restaurantStreet;
            this.houseNumber = res.data.formfillData.textFields.lb_restaurantStreetNo;
            this.zipCode = res.data.formfillData.textFields.lb_restaurantZip;
            this.city = res.data.formfillData.textFields.lb_restaurantCity;
            this.email = res.data.formfillData.textFields.lb_restaurantEmail;
            this.phone = res.data.formfillData.textFields.lb_restaurantPhoneNo;
            this.eventbee_webshop_cashierId = res.data.formfillData.textFields.eventbee_webshop_cashierId;
          }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(fin => {
        this.loading = false;
      })
    },
    update() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.loading = true;

      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.GENERALINFO.UPDATE, {
        lb_restaurantName: this.displayedName,
        lb_restaurantStreet: this.street,
        lb_restaurantStreetNo: this.houseNumber,
        lb_restaurantZip: this.zipCode,
        lb_restaurantCity: this.city,
        lb_restaurantEmail: this.email,
        lb_restaurantPhoneNo: this.phone,
        eventbee_webshop_cashierId: this.eventbee_webshop_cashierId
      }).then(res => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_ActionSuccessful'),
          color: "success",
        });
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(fin => {
        this.loading = false;
      })
    },
    getCashiers() {
      this.loading = true;

      this.axios.get(ENDPOINTS.DATATABLES.SETTINGS.ALIAS).then((res) => {
        res.data.aaData.forEach((data) => {
          this.cashiers.push({ id: data[0], name: data[2] });
        });
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(() => {
        this.loading = false;
      })
    }
  },
  mounted() {
    this.getData()
    this.getCashiers();
  }

}
</script>

<style scoped>

</style>
