<template>
  <v-card flat tile>
    <v-img :src="imageUrl" :aspect-ratio="16/9" contain width="150">
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-row>
      </template>
    </v-img>

    <v-card-actions>
      <v-btn @click="deleteImage" :loading="deleteLoading" :disabled="deleteLoading" color="error" text>
        <v-icon>delete</v-icon> {{$t('generic.lang_delete')}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
    import {mapState} from "vuex";
    import {ENDPOINTS} from "@/config";

    export default {
        name: "TemplateImage",
        props: {
            image: String,
            uuid: String,
        },

        computed: {
            ...mapState([
                'api'
            ]),
            imageUrl() {
                return "https://img.3pos.de/unsafe/1200x490/billsys_"+this.api.auth.posID+"/original/"+this.image;
            }
        },

        data() {
            return {
                deleteLoading: false
            }
        },

        methods: {
            deleteImage() {
                this.deleteLoading = true;
              this.$swal({
                title: this.$t('generic.lang_warning'),
                text: this.$t('generic.lang_onYourOwnRiskItMayBreakYourSystem'),
                icon: "warning",
                confirmButtonText: this.$t('generic.lang_ok'),
                cancelButtonText: this.$t('generic.lang_cancel'),
                showCancelButton: true,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                  this.axios.post(ENDPOINTS.VOUCHERBEE.SETTINGS.IMAGETEMPLATE.DELETE, {
                    uuid: this.uuid
                  }).then((res) => {
                    if(res.data.success) {
                      this.$emit("imageDelete");
                    }

                    this.deleteLoading = false;
                  }).catch(() => {
                    this.deleteLoading = false;
                  });
                },
                allowOutsideClick: () => !this.$swal.isLoading()
              })
            }
        }
    }
</script>