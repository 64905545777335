<template>
  <div>
    <page-title :heading="$t('generic.lang_onlineGiftCardSale')" :icon=icon :subheading="$t('generic.lang_onlineGiftCardSale')"
    ></page-title>
    <div class="app-main__inner">
      <OnlineGiftCardSaleSettingsComponent/>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle";
import EventbeeSettingsComponent from "../../../components/eventbee/settings/EventbeeSettingsComponent";
import OnlineGiftCardSaleSettingsComponent
  from "@/components/settings/moduleSettings/onlinegiftcardsale/OnlineGiftCardSaleSettingsComponent.vue";
export default {
  name: "OnlineGiftCardSale",
  components: {OnlineGiftCardSaleSettingsComponent, PageTitle},
  data: () => ({
    icon: 'pe-7s-gift icon-gradient bg-tempting-azure',
  })
}
</script>

<style scoped>

</style>
