<template>
  <v-container fluid>
    <v-row>
      <v-col
          class="pb-0"
          cols="12"
          md="3"
          sm="12"
      >
        <v-row dense>
          <v-col
              class="pb-0"
              cols="12"
          >
            <div role="tablist">
              <b-card
                  :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'mb-1']"
                  no-body
              >
                <b-card-header
                    class="p-0"
                    header-tag="header"
                    role="tab"
                >
                  <v-list-item
                      @click="globalSettings = !globalSettings"
                      class="ma-0"
                      v-b-toggle.settings
                  >
                    <v-list-item-title
                        :class="
                        this.globalSettings
                          ? 'primary--text text-wrap'
                          : 'text-wrap'
                      "
                    >
                      {{ $t('settings.lang_settings') }}
                    </v-list-item-title>
                    <v-list-item-action>
                      <v-icon v-if="!this.globalSettings"
                      >keyboard_arrow_down</v-icon
                      >
                      <v-icon v-if="this.globalSettings"
                      >keyboard_arrow_up</v-icon
                      >
                    </v-list-item-action>
                  </v-list-item>
                </b-card-header>
                <b-collapse
                    :visible="this.globalSettings"
                    accordion="settings"
                    id="settings"
                    role="tabpanel"
                >
                  <b-card-body class="pa-0">
                    <b-list-group flush>
                      <b-list-group-item
                          :class="[
                          this.$vuetify.theme.dark ? 'dark-bg' : '',
                          this.currentTab === 'generalInfo'
                            ? 'primary--text'
                            : '',
                        ]"
                          @click="handleClick('generalInfo')"
                          class="items"
                      >
                        {{ $t('generic.lang_globalInformations') }}
                      </b-list-group-item>
                      <b-list-group-item
                          :class="
                          this.currentTab === 'payment' ? 'primary--text' : ''
                        "
                          @click="handleClick('payment')"
                          class="items"
                      >
                        {{ $t('generic.lang_zahlungsoptionen') }}
                      </b-list-group-item>

                      <b-list-group-item
                          :class="[
                          this.$vuetify.theme.dark ? 'dark-bg' : '',
                          this.currentTab === 'emailConfig'
                            ? 'primary--text'
                            : '',
                        ]"
                          @click="handleClick('emailConfig')"
                          class="items"
                      >
                        {{ $t('settings.lang_emailSettings') }}
                      </b-list-group-item>

                      <b-list-group-item
                          :class="[
                          this.$vuetify.theme.dark ? 'dark-bg' : '',
                          this.currentTab === 'dynamicContent'
                            ? 'primary--text'
                            : '',
                        ]"
                          @click="handleClick('dynamicContent')"
                          class="items"
                      >
                        {{ $t('generic.lang_dynamicContent') }}
                      </b-list-group-item>
                      <b-list-group-item
                          :class="[
                          this.$vuetify.theme.dark ? 'dark-bg' : '',
                          this.currentTab === 'imageTemplate'
                            ? 'primary--text'
                            : '',
                        ]"
                          @click="handleClick('imageTemplate')"
                          class="items"
                      >
                        {{ $t('settings.lang_imageTemplates') }}
                      </b-list-group-item>
                    </b-list-group>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col
          cols="12"
          md="9"
          sm="12"
      >
        <div v-if="currentTab === 'generalInfo'">
          <OnlineGiftCardSaleGeneralInfoComponent/>
        </div>
        <div v-if="currentTab === 'payment'">
          <OnlineGiftCardSalePaymentOptionComponent/>
        </div>
        <div v-if="currentTab === 'emailConfig'">
          <OnlineGiftCardSaleEmailConfigComponent/>
        </div>
        <div v-if="currentTab === 'dynamicContent'">
          <OnlineGiftCardSaleDynamicContentComponent/>
        </div>
        <div v-if="currentTab === 'imageTemplate'">
          <ImagesTemplate/>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import OnlineGiftCardSaleEmailConfigComponent
  from "@/components/settings/moduleSettings/onlinegiftcardsale/OnlineGiftCardSaleEmailConfigComponent.vue";
import OnlineGiftCardSaleGeneralInfoComponent
  from "@/components/settings/moduleSettings/onlinegiftcardsale/OnlineGiftCardSaleGeneralInfoComponent.vue";
import OnlineGiftCardSaleDynamicContentComponent
  from "@/components/settings/moduleSettings/onlinegiftcardsale/OnlineGiftCardSaleDynamicContentComponent.vue";
import OnlineGiftCardSalePaymentOptionComponent
  from "@/components/settings/moduleSettings/onlinegiftcardsale/OnlineGiftCardSalePaymentOptionComponent.vue";
import ImagesTemplate from "@/components/settings/moduleSettings/onlinegiftcardsale/imageTemplates/ImagesTemplate.vue";

export default {
  name: "OnlineGiftCardSaleSettingsComponent",
  components: {
    ImagesTemplate,
    OnlineGiftCardSalePaymentOptionComponent,
    OnlineGiftCardSaleDynamicContentComponent,
    OnlineGiftCardSaleGeneralInfoComponent,
    OnlineGiftCardSaleEmailConfigComponent

  },
  data() {
    return {
      text: 'example text',
      currentTab: 'generalInfo',
      globalSettings: '',
      // ----------------------------------
    };
  },
  methods: {
    handleClick(newTab) {
      this.currentTab = newTab;
    },
  },
  computed: {
    tabs: function () {
      return [
        {
          title: this.$t('generic.lang_basicData'),
          value: 'generalInfo',
        }
      ];
    },
  },
  mounted() {
  },
};
</script>

<style scoped>
.tabs > .tabs__item,
.tabs {
  width: auto !important;
  z-index: 1 !important;
}

.tabs__item_active,
.tabs__active-line,
.shadow-tabs .tab-item-line {
  padding-top: 20px !important;
}

.tabs__item {
  z-index: 1 !important;
}

.tabs__item:hover {
  border: none;
  outline: none;
}

.tabs__item:focus {
  border: none;
  outline: none;
}

.items {
  cursor: pointer !important;
}
</style>
